<template>
  <panel>
    <div class="profile-info">
      <avatar v-if="false" :profile="profile" :size="40" class="avatar" />

      <div>
        <span class="name">
          {{ profile.firstName || '' }} {{ profile.lastName || '' }}
        </span>
        <span class="email">{{ profile.email }}</span>
      </div>
    </div>
  </panel>
</template>

<script>
import Avatar from '@/components/common/ui/Avatar'

export default {
  components: {
    Avatar
  },

  props: {
    profile: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-info {
  display: flex;
  align-items: center;
  .avatar {
    margin-right: 10px;
  }
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #091520;
    &:hover {
      color: #409EFF;
    }
  }
  .email {
    display: block;
    font-size: 16px;
    color: #5a738a;
    margin-top: 3px;
  }
  .status {
    margin-left: 5px;
  }
}
</style>
