<template>
  <pull-to :top-load-method="load">
    <panel
      v-for="(panel, index) in panels"
      :key="index"
      :title="panel.title"
      collapsable
      :initiallyExpanded="index === 0"
    >
      <data-list :list="panel.items" />
    </panel>

    <template slot="top-block">
      <div>
        <div
          v-if="reloading"
          v-loading="reloading"
          class="basic-loader mb-20" />
      </div>
    </template>
  </pull-to>
</template>

<script>
import navigation from './navigation'

import PullTo from 'vue-pull-to'
import ProfileInfo from '@/components/customers/ProfileInfo'

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = []

    try {
      await Promise.all(promises)
      next()
    } catch {
      next({ name: 'Customers' })
    }
  },

  components: {
    PullTo,
    ProfileInfo
  },

  data () {
    return {
      reloading: false
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },
    registration () {
      return this.user?.registration
    },

    registrationData () {
      return this.registration?.data
    },

    panels () {
      return [
        {
          title: 'General',
          items: [
            { label: 'Profile', text: this.user.profileId },
            { label: 'Type', text: this.registration.type },
            { label: 'Since', text: this.$date(this.registration.createdAt, 'LL') },
            { label: 'Language', text: this.registration.profileSettings.language }
          ]
        },
        {
          title: 'Profile',
          items: [
            {
              label: 'First Name',
              text: this.user.firstName
            },
            {
              label: 'Last Name',
              text: this.user.lastName
            },
            {
              label: 'Sex',
              text: this.registrationData.sex
            },
            {
              label: 'SSN/Fiscal Number',
              text: null
            },
            {
              label: 'Date of Birth',
              text: this.$date(this.registrationData.dob, 'LL')
            },
            {
              label: 'Phone',
              text: this.registrationData.phone
            },
            {
              label: 'Mobile',
              text: this.registrationData.mobile
            },
            {
              label: 'Email',
              text: this.user.email
            }
          ]
        },
        {
          title: 'Address',
          items: [
            {
              label: 'Address',
              text: this.registrationData.address
            },
            {
              label: 'Address 2',
              text: this.registrationData.address2
            },
            {
              label: 'City',
              text: this.registrationData.city
            },
            {
              label: 'State/Province',
              text: this.registrationData.state
            },
            {
              label: 'ZIP/Postal Code',
              text: this.registrationData.zip
            },
            {
              label: 'Country',
              text: this.$country(this.registrationData.country)
            }
          ]
        }
        // {
        //   title: 'Contact',
        //   items: [
        //     { label: 'First Name', text: this.user.firstName },
        //     { label: 'Last Name', text: this.user.lastName },
        //     { label: 'Sex', text: this.registrationData.sex },
        //     { label: 'SSN/Fiscal Number', text: null },
        //     { label: 'Date of Birth', text: this.$date(this.registrationData.dob, 'LL') },
        //     { label: 'Phone', text: this.registrationData.phone },
        //     { label: 'Mobile', text: this.registrationData.mobile },
        //     { label: 'Email', text: this.user.email }
        //   ]
        // }
      ]
    }
  },

  async created () {
    this.setPageTitle()
  },

  methods: {
    async load (loaded) {
      this.reloading = true
      try {
        await this.$store.dispatch('app/loadData', true)
      } catch (e) {
        console.error(e)
      } finally {
        loaded('done')
        this.reloading = false
      }
    },

    setPageTitle () {
      this.$store.commit('page/setPageInfo', {
        title: 'My Profile'
      })

      this.$store.commit('page/setTabs', navigation)
    }
  }
}
</script>
